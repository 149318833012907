import React, { useEffect, useState } from "react";
import "./Coupons.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CouponCard from "./CouponCard";
import axios from "axios";


export default function CouponCodesHome() {
  const [arrayrender, setArrayrender] = useState([]);

  const nav = useNavigate()

  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");


  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/coupons/showCoupons`);

      console.log(response.data)
      setArrayrender(response.data.coupons)

    } catch(e) {
      alert("Error fetching coupons")
    }
  }

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleDelete = async (code) => {
    try {
      console.log(code)
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupons/deleteCoupon/${code}`);

      console.log(response.data)
      setStatusMessage("Coupon deleted successfully!");
      setTimeout(() => {
        setStatusMessage("")
      }, 1000);
      setStatusType("success");
      fetchCoupons();

    } catch(e) {
      setStatusMessage("Error: deleting coupon");
      setTimeout(() => {
        setStatusMessage("")
      }, 1000);
      setStatusType("error");
      alert("Error deleting coupons")
    }
  }

  const handleUpdate = (code) => {
    nav(`/admin/update-coupon/${code}`)
  }

  return (
    <div className="container">
      <div className='admin-page-navbar'>
        <div className='admin-page-heading'>Welcome FinnoFarms Admin</div>
        <button 
        className='login-btn-admin'
          onClick={() => {
            Navigate('/admin/chat-to-customers')
          }}
        >
          Admin Chat
        </button>
        <Link to='/admin'>
          <button className='login-btn-admin'> 
            Home
          </button>
        </Link>
        <button className='login-btn-admin' onClick={()=>{
          alert("Log out successful!!");
          localStorage.removeItem("adminToken");
          Navigate('/admin/login');
          }}>LOG OUT
        </button>
      </div>
      <h1 className="heading">Coupons Admin Home</h1>
      <div className="parentdiv1">
        <Link to={"/admin/create-coupon"} className="btncoupon">
          Create New Coupon
        </Link>
        <div style={{ width: "80%" }}>
          <h2 className="heading">View Or Update Previous Coupons</h2>
          {arrayrender.length ? (
            <div className="coupongrid">
              {arrayrender.map((coupon, index) => {
                return (
                  <div key={index} className="renderdiv">
                    <CouponCard
                        code={coupon.couponCode}
                        disValue={coupon.discountAmount}
                        expiryDate={coupon.expiryDate.split("T")[0]}
                        onDelete={() => {handleDelete(coupon.couponCode)}}
                        onUpdate={() => {handleUpdate(coupon.couponCode)}}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="ptag">No Coupons Yet</p>
          )}
        </div>
        {statusMessage && (
          <div
            style={{
              margin: "20px",
              padding: "10px",
              borderRadius: "4px",
              color: statusType === "success" ? "green" : "red",
              backgroundColor: statusType === "success" ? "#e0f7e0" : "#fde0e0",
              textAlign: "center",
            }}
          >
            {statusMessage}
          </div>
        )}
      </div>
    </div>
  );
}
