import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateCoupon() {
  const [formData, setFormData] = useState({
    code: "",
    disValue: "", 
    expiryDate: new Date().toISOString().split("T")[0],
    selectUsers: "",
    selectPincodes: "",
    isFirstOrder: false,
    activate_amount: "",
    isActive: true,
  });

  const nav = useNavigate();

  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const couponData = {
      couponCode: formData.code,
      discountAmount: Number(formData.disValue), 
      expiryDate: new Date(formData.expiryDate), 
      selectUsers: formData.selectUsers.trim()
        ? formData.selectUsers.split(",").map((item) => item.trim())
        : [],
      selectPincodes: formData.selectPincodes.trim()
        ? formData.selectPincodes.split(",").map((item) => item.trim())
        : [],
      isFirstOrder: formData.isFirstOrder,
      activateAmount: Number(formData.activate_amount), 
      isActive: formData.isActive,
    };

    const selectedExpiryDate = new Date(formData.expiryDate);
    const today = new Date();

    if (selectedExpiryDate < today) {
      setStatusMessage("Error: Expiry date cannot be in the past!");
      setStatusType("error");

      setTimeout(() => {
        setStatusMessage("");
        setStatusType("");
      }, 3000);
      return;
    }

    try {

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupons/generateCoupon`, couponData);

      console.log(response.data)

      setStatusMessage("Coupon created successfully!");
      setStatusType("success");

      setTimeout(() => {
        setStatusMessage("");
        setStatusType("");
        nav("/admin/couponcodeaccess")
      }, 3000);


    } catch (e) {
      setStatusMessage("Error creating coupon!");
      setStatusType("error");

      setTimeout(() => {
        setStatusMessage("");
        setStatusType("");
      }, 3000);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "40px",
        backgroundColor: "#f4f4f4",
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#026ab4",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Create Coupon
        </h2>

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Coupon Code (Like Finno50)
            </label>
            <input
              type="text"
              name="code"
              value={formData.code}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Discount Value (in Rs.)
            </label>
            <input
              type="number"
              name="disValue"
              value={formData.disValue}
              onChange={handleChange}
              min="10"
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Expiry Date
            </label>
            <input
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Select Users (Comma separated phone number) (Empty if not)
            </label>
            <input
              type="text"
              name="selectUsers"
              value={formData.selectUsers}
              onChange={handleChange}
              placeholder="e.g. user1,user2"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Select Pincodes (Comma separated pincode) (Empty if not)
            </label>
            <input
              type="text"
              name="selectPincodes"
              value={formData.selectPincodes}
              onChange={handleChange}
              placeholder="e.g. 123456,789012"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Is First Order ? (Users with 1st order only)
            </label>
            <input
              type="checkbox"
              name="isFirstOrder"
              checked={formData.isFirstOrder}
              onChange={handleChange}
              style={{ marginRight: "8px" }}
            />
            <span>{formData.isFirstOrder ? "Yes" : "No"}</span>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Activate Amount (in Rs.) (Above which coupon applicable)
            </label>
            <input
              type="number"
              name="activate_amount"
              value={formData.activate_amount}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ddd",
              }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Is Active ? (Should coupon currently work ?)
            </label>
            <input
              type="checkbox"
              name="isActive"
              checked={formData.isActive}
              onChange={handleChange}
              style={{ marginRight: "8px" }}
            />
            <span>{formData.isActive ? "Yes" : "No"}</span>
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              style={{
                backgroundColor: "#026ab4",
                color: "white",
                padding: "12px 24px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            >
              Create Coupon
            </button>
          </div>
        </form>
        {statusMessage && (
          <div
            style={{
              margin: "20px",
              padding: "10px",
              borderRadius: "4px",
              color: statusType === "success" ? "green" : "red",
              backgroundColor: statusType === "success" ? "#e0f7e0" : "#fde0e0",
              textAlign: "center",
            }}
          >
            {statusMessage}
          </div>
        )}
      </div>
    </div>
  );
}
