import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../CSSStyling/Admin/refundOrders.css";
import { Link, useNavigate } from "react-router-dom";
function RefundList() {
  const Navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    fetchRefundOrders();
  }, []);

  const notify = (message) => {
    toast(message);
  };
  const triggerRefundForm = async (order) => {
    Navigate(`/admin/issue-refund/${order.order_id}`);
  };
  const fetchRefundOrders = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/order/get-refund-list`
      );
      const data = await response.json();
      setOrders(data.orders);
    } catch (error) {
      console.error("Error fetching refund orders:", error);
    }
  };
  return (
    <div>
    <div className='admin-page-navbar'>
        <div className='admin-page-heading'>Welcome FinnoFarms Admin</div>
        <button 
        className='login-btn-admin'
        onClick={() => {
            Navigate('/admin/chat-to-customers')
        }}
        >
            Admin Chat
        </button>
        <Link to='/admin'>
            <button className='login-btn-admin'> 
                Home
            </button>
        </Link>
        <button className='login-btn-admin' onClick={()=>{
        alert("Log out successful!!");
        localStorage.removeItem("adminToken");
        Navigate('/admin/login');
        }}>
            LOG OUT
        </button>
    </div>
      <h2>Orders to be Refunded</h2>
      <div className="refund-order-container">
        {orders.map((order) => (
          <div className="refund-order" key={order.order_id}>
            <div
              className="order_title"
              onClick={() =>
                setSelectedOrder((prevOrder) =>
                  prevOrder === order ? null : order
                )
              }
            >
              <span
                className={`arrow-icon ${
                  selectedOrder === order ? "open" : "close"
                }`}
              ></span>
              Order ID: {order.order_id}
            </div>
            {selectedOrder?.order_id === order.order_id && (
              <div className="order-item">
                <section className="order-details">
                  <p>Order Date : {order.orderPlaced_date}</p>
                  <p>
                    Delivery Address Title :
                    {order.delivery_address.title ?? "N/A"}
                  </p>
                  <p>
                    Delivery Address Apartment :
                    {order.delivery_address.apartmentAddress ?? "N/A"}
                  </p>
                  <p>
                    Delivery Address Street :
                    {order.delivery_address.street_address ?? "N/A"}
                  </p>
                  <p>
                    Delivery Landmark, City, State, Pincode :
                    {order.delivery_address.landmark ?? "N/A"},{" "}
                    {order.delivery_address.city ?? "N/A"},{" "}
                    {order.delivery_address.state ?? "N/A"},{" "}
                    {order.delivery_address.pincode ?? "N/A"},{" "}
                  </p>
                  <p>Customer Mobile No : {order.mobileNo}</p>
                  <p>Order Total Amount : Rs. {order.bill_details.totalAmount}</p>
                  
                  <p>Payment Mode: {order.paymentMode}</p>
                  <p>Payment ID: {order.payment_id}</p>
                  <p>Preferred TimeSlot : {order.timeSlot}</p>
                  <p>Refund Status : {order.status}</p>
                </section>
                Ordered Products:
                <ul>
                  {order.products.map((product) => (
                    <div key={product.product_id}>
                      <li>
                        <strong>Product ID:</strong> {product.product_id}
                        <br />
                        <strong>Product Name:</strong> {product.product_name}
                        <br />
                        {product.fish_size !== "NA" && (
                          <div>
                            <strong>Fish Size:</strong> {product.fish_size}
                            <br />
                          </div>
                        )}
                        {product.piece_size !== "NA" && (
                          <div>
                            <strong>Piece Size:</strong> {product.piece_size}
                            <br />
                          </div>
                        )}
                        {product.cut_type !== "NA" && (
                          <div>
                            <strong>Cut-Type:</strong> {product.cut_type}
                            <br />
                          </div>
                        )}
                        <strong>Quantity:</strong> {product.product_quantity}
                        <br />
                        <strong>Product Price:</strong> Rs.{" "}
                        {product.product_price}
                        <br />
                        <strong>Order Price:</strong> Rs.{" "}
                        {product.product_price * product.product_quantity}
                        <br />
                      </li>
                      <br />
                    </div>
                  ))}
                </ul>
                Refunded Products :
                <ul>
                  {order.refunded_products.map((product) => (
                    <li key={product.product_id}>
                      <strong>Product ID:</strong> {product.product_id}
                      <br />
                      <strong>Quantity:</strong> {product.product_quantity}
                      <br />
                      <strong>Price:</strong> {product.product_price}
                    </li>
                  ))}
                </ul>
                <div className="buttons-container">
                  <button
                    className="accept-button"
                    onClick={() => {
                      triggerRefundForm(order);
                    }}
                  >
                    {order.paymentMode === "Online"
                      ? "Issue Refund"
                      : "Mark as Refunded"}
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
}

export default RefundList;
