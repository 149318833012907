import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./Adminstyleasnotailwind.css";
import { Link, Navigate, useNavigate } from "react-router-dom";

const socket = io("https://www.chat.finnofarms.in");

const AdminChat = () => {
  const nav = useNavigate();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const chatWindowRef = useRef(null);

  const fetchUsers = async () => {
    try {
      const response = await fetch("https://www.chat.finnofarms.in/api/chat/users");
      const userIds = await response.json();
      const updatedUsers = userIds.map((userId) => ({
        userId,
        lastMessageTime: 0,
        unread: true, 
      }));
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();

    socket.on("newUser", (newUserId) => { 
      setUsers((prevUsers) => {
     
        const userExists = prevUsers.some(user => user.userId === newUserId);
        if (!userExists) {
      
          return [...prevUsers, { userId: newUserId, lastMessageTime: 0, unread: true }];
        }
        return prevUsers; 
      });
    });

    return () => {
      socket.off("newUser");
    };
  }, []);

  useEffect(() => {
    if (selectedUser) {
      socket.emit("joinRoom", selectedUser);
      setMessages([]);

      const handlePreviousMessages = (fetchedMessages) => {
        setMessages(fetchedMessages);

        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.userId === selectedUser ? { ...user, unread: false } : user
          )
        );
      };

      const handleNewMessage = (message) => {
        if (message.userId === selectedUser) {
          setMessages((prevMessages) => {
            if (!prevMessages.some((msg) => msg._id === message._id)) {
              return [...prevMessages, message];
            }
            return prevMessages;
          });

          setUsers((prevUsers) => {
            const updatedUsers = prevUsers.map((user) =>
              user.userId === message.userId
                ? { ...user, lastMessageTime: Date.now(), unread: false } 
                : user
            );

            return updatedUsers.sort(
              (a, b) => b.lastMessageTime - a.lastMessageTime
            );
          });
        }
      };

      socket.on("previousMessages", handlePreviousMessages);
      socket.on("newMessage", handleNewMessage);

      return () => {
        socket.off("previousMessages", handlePreviousMessages);
        socket.off("newMessage", handleNewMessage);
        socket.emit("leaveRoom", selectedUser);
      };
    }
  }, [selectedUser]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    const messageData = {
      _id: Date.now().toString(),
      from: "admin",
      message: newMessage,
      userId: selectedUser,
    };

    socket.emit("sendMessage", messageData);
    setNewMessage("");
  };

  const handleSelectUser = (userId) => {
    setSelectedUser(userId);

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.userId === userId ? { ...user, unread: false } : user
      )
    );
  };

  return (
    <>
      <div className='admin-page-navbar'>
        <div className='admin-page-heading'>Welcome FinnoFarms Admin</div>
        <button 
        className='login-btn-admin'
          onClick={() => {
            Navigate('/admin/chat-to-customers')
          }}
        >
          Admin Chat
        </button>
        <Link to='/admin'>
          <button className='login-btn-admin'> 
            Home
          </button>
        </Link>
        <button className='login-btn-admin' onClick={()=>{
          alert("Log out successful!!");
          localStorage.removeItem("adminToken");
          Navigate('/admin/login');
          }}>LOG OUT
        </button>
      </div>
      <div className="chat-container">
        <div className="users-list-container">
          <div className="check">
            <h2 className="title">Finno Users</h2>
            <button
              className="homebtn"
              onClick={() => {
                nav("/admin");
              }}
            >
              Home
            </button>
          </div>
          {users.length ? (
            <ul className="users-list">
              {users.map(({ userId, unread }) => (
                <li
                  key={userId}
                  onClick={() => handleSelectUser(userId)}
                  className={`user-item ${
                    selectedUser === userId ? "selected" : unread ? "unread" : ""
                  }`}
                >
                  User - {userId} {unread && <span className="unread-indicator"></span>}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-user-selected">No users found.</p>
          )}
        </div>

        <div className="chat-window-container">
          {selectedUser ? (
            <>
              <h2 className="chat-title">
                Messages from {selectedUser}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="close-icon"
                  onClick={() => setSelectedUser(null)}
                >
                  <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" />
                </svg>
              </h2>
              <div className="chat-window" ref={chatWindowRef}>
                {messages.map((msg) => (
                  <div
                    key={msg._id}
                    className={`message ${
                      msg.from === "admin" ? "admin-message" : "user-message"
                    }`}
                  >
                    <div className="message-content">
                      <strong>{msg.from === "admin" ? "Admin" : "User"}:</strong>{" "}
                      {msg.message}
                    </div>
                  </div>
                ))}
              </div>
              <div className="message-input-container">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                  className="message-input"
                  aria-label="Message input"
                />
                <button onClick={handleSendMessage} className="send-button">
                  Send
                </button>
              </div>
            </>
          ) : (
            <p className="no-user-selected">Select a user to start chatting.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminChat;