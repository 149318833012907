import React from 'react';

export default function CouponCard({ code, disValue, expiryDate, onDelete, onUpdate }) {
  return (
    <div style={{
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '16px',
      backgroundColor: 'white',
      width: '100%',
      color: '#333',
      maxWidth: '300px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}>

      <div style={{
        backgroundColor: '#026ab4',
        padding: '8px 16px',
        borderRadius: '8px 8px 0 0',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
      }}>
        Coupon Code: {code}
      </div>

      <div style={{ padding: '16px', textAlign: 'center' }}>
        <h3 style={{ margin: '0' }}>Discount: {disValue} Rs.</h3>
        <p style={{ margin: '8px 0 0', color: '#666' }}>Expires on: {expiryDate}</p>
      </div>


      <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
        <button onClick={onUpdate} style={{
          backgroundColor: '#026ab4',
          color: 'white',
          border: 'none',
          padding: '8px 12px',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}>
          <span style={{ marginRight: '8px' }}>🔄</span> Update
        </button>

        <button onClick={onDelete} style={{
          backgroundColor: '#ff4d4d',
          color: 'white',
          border: 'none',
          padding: '8px 12px',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}>
          <span style={{ marginRight: '8px' }}>🗑️</span> Delete
        </button>
      </div>
    </div>
  );
}
